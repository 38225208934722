//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import PaperworkWrap from '../PaperworkWrap'
import {
  Path,
  Nav,
  useState,
  Button,
  useCallback,
  useNavigate,
  FieldText,
  observer,
  useEffect,
  ModelPaperwork,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

function PaperworkInfoRelease() {
  const navigate = useNavigate()

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    const goBack = ModelPaperwork.backToSummary
    ModelPaperwork.setBackToSummary(false)
    if (goBack) {
      //>>>>>
      navigate(-1)
    } else {
      //>>>>>
      navigate(Path.PaperworkSummary)
    }
  }, [navigate])

  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={
          ModelPaperwork.backToSummary ? Path.PaperworkSummary : Path.Paperwork4
        }
        title='Information Release'
        progress={5 / 6}
      />
      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          <h2>Authorization of Information Release</h2>
          <p>
            The following individuals are authorized to receive info regarding
            my medical treatment.
          </p>

          {/* === First Person === */}
          <FieldText
            value={ModelPaperwork.paperwork.authorizedIndividual1}
            label="Authorized Individual's Name (optional)"
            change={(value) => {
              ModelPaperwork.paperwork.authorizedIndividual1 = value
              ModelPaperwork.setPaperwork(ModelPaperwork.paperwork)
            }}
          />

          {/* === First Person === */}
          <FieldText
            value={ModelPaperwork.paperwork.authorizedIndividual2}
            label="Authorized Individual's Name (optional)"
            change={(value) => {
              ModelPaperwork.paperwork.authorizedIndividual2 = value
              ModelPaperwork.setPaperwork(ModelPaperwork.paperwork)
            }}
          />

          {/* === First Person === */}
          <FieldText
            value={ModelPaperwork.paperwork.authorizedIndividual3}
            label="Authorized Individual's Name (optional)"
            change={(value) => {
              ModelPaperwork.paperwork.authorizedIndividual3 = value
              ModelPaperwork.setPaperwork(ModelPaperwork.paperwork)
            }}
          />
        </div>

        {/* === Continue === */}
        <div className={css.buttonWrap}>
          <Button
            primary
            clicked={clickContinue}
            text={ModelPaperwork.backToSummary ? 'Save Changes' : 'Continue'}
          />
        </div>
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkInfoRelease)
