//:::::::::::::::::::::::::
import css from './selector.module.css'
//:::::::::::::::::::::::::

export default function Selector(props: {
  items: string[]
  selected: string
  itemWidth?: number
  inline?: boolean
  buttonStyle?: boolean
  clicked: (item: string, index?: number) => void
}) {
  function getItems() {
    if (props.items.length === 0) {
      //Sometimes the values are loaded from a server and we need a pending state
      return <li>Loading...</li>
    } else {
      //Return all the items as a list of <li> elements
      return props.items.map((item, index) => (
        <li
          role='button'
          key={item}
          tabIndex={0}
          className={`${item === props.selected ? css.selected : ''}`}
          style={{ width: props.itemWidth ? `${props.itemWidth}px` : '50%' }}
          onClick={() => props.clicked(item, index)}
          onKeyDown={(e) => {
            console.log(
              `-${e.key}-`,
              props.selected,
              item,
              item === props.selected,
            )
            if (e.key === ' ' && props.selected !== item) {
              props.clicked(item, index)
            }
          }}
        >
          {item}
        </li>
      ))
    }
  }
  return (
    <ul
      className={`${css.selector} ${props.inline ? css.inline : ''} ${
        !props.itemWidth && css.fullWdith
      } ${props.buttonStyle ? css.buttonStyle : ''}`}
    >
      {getItems()}
    </ul>
  )
}
