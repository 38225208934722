//http://localhost:3000/paperwork/1?bookingId=26xmXo&locationId=GgbNNg&isUrgentCare=true&device=web
//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import {
  useState,
  Nav,
  Path,
  useNavigate,
  useCallback,
  Button,
  ModelPaperwork,
  useEffect,
  Helper,
  LoginBanner,
  Types,
  Divider,
  observer,
  ModelBooking,
  useSearchParams,
  ModelModal,
  BookingInformationBO,
  moment,
  ModelVirtualCare,
} from 'src/_Shared/global'
import PaperworkWrap from '../PaperworkWrap'
import PaperworkPatientInfo from './PaperworkPatientInfo'
import PaperworkPatientAddress from './PaperworkPatientAddress'
import PaperworkPatientEmergency from './PaperworkPatientEmergency'
import accountStore from 'src/_Shared/stores/account'
import insuranceStore from 'src/_Shared/stores/insurance'
import { useLayoutEffect } from 'react'
//:::::::::::::::::::::::::

function PaperworkPatient() {
  const navigate = useNavigate()
  const [params] = useSearchParams()

  //==============================
  //#region State
  //==============================
  const [formReady, setFormReady] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const fetchInsurances = insuranceStore.getState().fetchInsurances

  //==============================
  //#region Loading info from Booking
  //==============================

  const navigateIfVirtualCare = useCallback(
    (booking: BookingInformationBO | null, clinicId: string | null) => {
      console.info('[PaperworkPatient] clinicId:', clinicId)
      const isVirtualClinic = ModelVirtualCare.isVirtualCare(
        booking?.location_id ?? clinicId ?? undefined,
      )
      console.info('[PaperworkPatient] isVirtualClinic:', isVirtualClinic)
      console.info('[PaperworkPatient] booking:', booking)
      if (booking && isVirtualClinic) {
        navigate(Path.PaperworkVirtual1)
      }
    },
    [navigate],
  )
  useLayoutEffect(() => {
    const clinicId =
      localStorage.getItem('clinicId') ?? params.get('clinicId') ?? ''
    navigateIfVirtualCare(null, clinicId)
  }, [navigateIfVirtualCare, params])

  useEffect(() => {
    //This function is used by the native apps since they can only send us query parameters to get booking and patient info
    async function getBookingAndPaperwork(bookingId: string) {
      ModelModal.showLoader('Please Wait', 'Getting patient info...')
      //Get booking info
      const booking = await ModelBooking.getBookingDetails(bookingId)
      if (!booking) return

      navigateIfVirtualCare(booking, null)

      const booker = ModelBooking.getBooker()

      if (!booker) {
        //Set booker (used in PaperworkGuarantor screen)
        ModelBooking.setBooker({
          firstName: params.get('bookerFirstName') ?? '',
          lastName: params.get('bookerLastName') ?? '',
          dateOfBirth: params.get('bookerDateOfBirth') ?? '',
          phone: params.get('bookerPhone') ?? '',
          email: params.get('bookerEmail') ?? '',
          sex: params.get('bookerSex') ?? '',
          ethnicity: params.get('bookerEthnicity') ?? '',
          race: params.get('bookerRace') ?? '',
          address: params.get('bookerAddress') ?? '',
          apartment: params.get('bookerApartment') ?? '',
          city: params.get('bookerCity') ?? '',
          country: params.get('bookerCountry') ?? '',
          state: params.get('bookerState') ?? '',
          zip: params.get('bookerZip') ?? '',
        })
      }

      await fetchInsurances()
      console.info('*** Fetched insurances')

      //---
      let paperworkAccountValues = getPaperworkAccountValues(booking)

      //Get prior paperwork
      const priorPaperwork = await ModelPaperwork.getPriorPaperwork(bookingId)

      //---
      ModelPaperwork.setPaperwork({
        ...ModelPaperwork.paperwork,
        //Pull in the account info via the URL from the native apps
        account: {
          ...ModelPaperwork.paperwork.account,
          ...paperworkAccountValues,
        },
        //Some of these values need to check for existing ones in case they edit their paperwork values
        responses: {
          ...ModelPaperwork.paperwork.responses,
          ...priorPaperwork.responses,
        },
      })
      ModelModal.hide()
    }

    const bookingId = localStorage.getItem('bookingId')
    //Look up the patient info with the booking ID (the native apps use this)
    if (bookingId) getBookingAndPaperwork(bookingId)
  }, [params])

  //==============================
  //#region Additional Patient Values
  //==============================
  const getPaperworkAccountValues = (
    booking: BookingInformationBO,
  ): Partial<Types.Account> => {
    const { account } = accountStore.getState()
    const booker = ModelBooking.getBooker()
    const values: Partial<Types.Account> = {
      firstName: booking.first_name,
      lastName: booking.last_name,
      dateOfBirth: Helper.convertDateOfBirthSimpleFromAPI(booking.birth_date),
      phone: Helper.convertPhoneFromAPI(booking.phone),
      email: booking.email ?? '',
      country: 'USA',
    }
    let patient: Types.Patient | undefined
    if (localStorage.getItem('patient')) {
      patient = JSON.parse(localStorage.getItem('patient')!) as Types.Patient
    }

    // Native: (no patient in localStorage. booker is set in getBookingAndPaperwork)
    if (!patient && booker) {
      const isDependent =
        moment(booking.birth_date).format('MM/DD/YYYY') !== booker.dateOfBirth
      return isDependent
        ? {
            ...values,
            city: booking.address_city,
            address: booking.address_street,
            apartment: booking.address_street_secondary,
            state: booking.address_state,
            zip: booking.address_zip,
            ...(booking.birth_sex ? { birthSex: booking.birth_sex } : {}),
          }
        : {
            ...values,
            ethnicity: booker.ethnicity,
            race: booker.race,
            city: booker.city,
            address: booker.address,
            apartment: booker.apartment,
            country: booker.country || 'USA',
            state: booker.state,
            zip: booker.zip,
            ...(booker.sex ? { birthSex: booker.sex } : {}),
          }
    }

    // Web: logged out (no account)
    if (!account && patient) {
      return {
        ...values,
        // so users don't have to set sex twice (also set on BookInfoForm)
        // Does not apply to appointment for dependent (since sex is not on BookPatientForm)
        ...(patient?.sex ? { birthSex: patient.sex } : {}),
      }
    }

    // Web: logged in
    if (patient?.nextGenId && account) {
      const patientIsDependent = patient.nextGenId !== account?.nextGenPersonId

      if (patientIsDependent) {
        const dependent = account.dependents?.find(
          (d) => d.nextGenPersonId === patient?.nextGenId,
        )
        return dependent
          ? {
              ...values,
              ethnicity: dependent.ethnicityDescription,
              race: dependent.raceDescription,
              address: dependent.address1,
              apartment: dependent.address2,
              city: dependent.city,
              state: dependent.state,
              zip: dependent.zipCode,
              birthSex: dependent.sex,
            }
          : values
      } else {
        return {
          ...values,
          ethnicity: account.ethnicityDescription,
          race: account.raceDescription,
          address: account.address1,
          apartment: account.address2,
          city: account.city,
          state: account.state,
          zip: account.zipCode,
          birthSex: account.sex,
        }
      }
    }
    return values
  }

  //==============================
  //#region Form Validation
  //==============================
  useEffect(() => {
    const ready =
      //Patient Information
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.firstName) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.lastName) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.dateOfBirth) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.phone) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.email) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.birthSex) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.ethnicity) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.race) &&
      //Address
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.address) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.city) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.country) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.state) &&
      Helper.isNotEmpty(ModelPaperwork.paperwork.account.zip)

    setFormReady(ready)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ModelPaperwork.paperwork.account])

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    const goBack = ModelPaperwork.backToSummary
    ModelPaperwork.setBackToSummary(false)

    if (goBack) {
      //>>>>>
      navigate(-1)
    } else if (ModelPaperwork.patientIsOver18()) {
      //>>>>>
      navigate(Path.Paperwork3)
    } else {
      //>>>>>
      navigate(Path.Paperwork2)
    }
  }, [navigate])

  //==============================
  //#region Native App Hide Login
  //==============================
  useEffect(() => {
    const native = params.get('native')
    //Persist this in case the user goes forward then back on native and the query param no longer exists
    if (native) {
      localStorage.setItem('native', 'yes')
    }

    const isNativeApp =
      native === 'yes' || localStorage.getItem('native') === 'yes'
    //Never show on native
    setShowLogin(!isNativeApp)
  }, [params])

  //==============================
  //#region UI
  //==============================
  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={ModelPaperwork.backToSummary ? Path.PaperworkSummary : Path.Home}
        title='Patient Information'
        progress={1 / 6}
      />

      <div className={css.paperwork}>
        {showLogin && <LoginBanner message='to Save Time' />}

        <div className={css.paperworkInner}>
          <h2>Patient Information</h2>
          <p>
            We need a few more details to help NextCare deliver the highest
            quality of care during your visit. 100% confidential and secure.
          </p>

          {/* === Patient Information === */}
          <>
            <PaperworkPatientInfo
              values={ModelPaperwork.paperwork}
              change={(values) => ModelPaperwork.setPaperwork(values)}
            />
            <Divider />
            <h2>Patient Address</h2>
            <PaperworkPatientAddress
              values={ModelPaperwork.paperwork}
              change={(values) => ModelPaperwork.setPaperwork(values)}
            />
            <Divider />

            <h2>Emergency Contact</h2>
            <PaperworkPatientEmergency
              values={ModelPaperwork.paperwork}
              change={(values) => ModelPaperwork.setPaperwork(values)}
            />
          </>
        </div>
        {/* === Continue === */}
        <div className={css.buttonWrap}>
          <Button
            primary
            disabled={!formReady}
            clicked={clickContinue}
            text={ModelPaperwork.backToSummary ? 'Save Changes' : 'Continue'}
          />
        </div>
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkPatient)
