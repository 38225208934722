//:::::::::::::::::::::::::
import css from './uploadCard.module.css'
import {
  Button,
  Icon,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function TakePhoto(props: {
  photo?: string
  ready: (file: string) => void
}) {
  //Using a square aspect ratio helps prevent weirdness across browsers
  const photoWidth = 250
  const photoHeight = 250

  //==============================
  //#region State
  //==============================
  const [photoPermission, photoPermissionSet] = useState(false)
  const [photoReady, photoReadySet] = useState(false)

  const videoRef = useRef<HTMLVideoElement>(null)
  const photoRef = useRef<HTMLCanvasElement>(null)

  const clearStreams = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    })
    stream.getTracks().forEach(function (track) {
      track.stop()
    })
  }

  //==============================
  //#region Load Photo
  //==============================
  useEffect(() => {
    if (!props.photo) return

    var image = new Image()
    image.onload = function () {
      let photo = photoRef.current
      if (!photo) return

      let context = photo.getContext('2d')
      if (!context) return
      context.clearRect(0, 0, photoWidth, photoHeight) //Clear before redrawing
      context.filter = 'grayscale(1)' //Convert to grayscale
      context.drawImage(image, 0, 0, photoWidth, photoHeight)
    }
    //Load image
    image.src = props.photo

    //Enable state to show cached photo
    photoPermissionSet(true)
    photoReadySet(true)
    return () => {
      // Clear the streams on unmount
      clearStreams()
    }
  }, [props.photo])

  //==============================
  //#region Use Camera
  //==============================
  const clickUseCamera = useCallback(async () => {
    startVideo()
  }, [])

  //==============================
  //#region Take Photo
  //==============================
  const clickTakePhoto = useCallback(async () => {
    photoReadySet(true)

    let video = videoRef.current
    let photo = photoRef.current
    if (!photo || !video) return

    //Draw a frame from the video on to the canvas to look like a photo
    let context = photo.getContext('2d')
    if (!context) return
    context.filter = 'grayscale(1)' //Convert to grayscale

    context.drawImage(video, 0, 0, photoWidth, photoHeight)

    //:::
    //Send base64 image string to parent
    const image = photo.toDataURL('image/jpeg', 0.8)
    console.info('Photo Taken:', image)
    props.ready(image)

    stopVideo()
  }, [videoRef, photoRef])

  async function startVideo() {
    try {
      photoPermissionSet(true)
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: photoWidth,
          height: photoHeight,
          facingMode: 'environment',
        },
        audio: false,
      })

      let video = videoRef.current
      if (video) {
        video!.srcObject = stream
        video.play()
      }
    } catch (error) {
      console.log(error)
    }
  }

  //==============================
  //#region Stop Video
  //==============================
  async function stopVideo() {
    try {
      //Stop all streams
      clearStreams()

      //Null out the video (can take 20 seconds for the web cam light to go off)
      let video = videoRef.current
      if (video) {
        video!.srcObject = null
      }
    } catch (error) {
      console.log(error)
    }
  }

  //==============================
  //#region Retake Photo
  //==============================
  const clickRetakePhoto = useCallback(() => {
    startVideo()
    //Reset back
    photoReadySet(false)
  }, [])

  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.uploadPhoto}>
      {photoPermission ? (
        <>
          {/* === Photo/Video Stack === */}
          <div className={css.camera}>
            {/* === Photo Captured === */}
            <canvas
              ref={photoRef}
              width={photoWidth}
              height={photoHeight}
              className={`${photoReady ? css.visible : ''}`}
            />

            {/* === Photo Preview Video === */}
            <video
              ref={videoRef}
              width={photoWidth}
              height={photoHeight}
              className={`${photoReady ? '' : css.visible}`}
              playsInline={true}
              webkit-playsinline={true}
            />
          </div>

          {/* === Take Photo === */}
          {photoReady ? (
            <Button clicked={clickRetakePhoto} block={true}>
              <p className={css.button}>
                <span>
                  <Icon name='photo' width={20} />
                </span>
                <em>Retake Photo</em>
              </p>
            </Button>
          ) : (
            <Button clicked={clickTakePhoto} block={true}>
              <p className={css.button}>
                <span>
                  <Icon name='photo' width={20} />
                </span>
                <em>Take a Photo</em>
              </p>
            </Button>
          )}
        </>
      ) : (
        <>
          {/* === Get Permission to Use Camera === */}
          <Button clicked={clickUseCamera} block={true}>
            <p className={css.button}>
              <span>
                <Icon name='photo' width={20} />
              </span>
              <em>Use Camera</em>
            </p>
          </Button>
        </>
      )}
    </div>
  )
}
