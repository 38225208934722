//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import PaperworkWrap from '../PaperworkWrap'
import {
  Path,
  Nav,
  useEffect,
  useState,
  Types,
  ModelPaperwork,
  Button,
  useCallback,
  useNavigate,
  Selector,
  observer,
} from 'src/_Shared/global'
import PaperworkEmployerInfo from './PaperworkEmployerInfo'
import PaperworkEmployerReason from './PaperworkEmployerReason'
//:::::::::::::::::::::::::

function PaperworkEmployer() {
  const navigate = useNavigate()

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    const goBack = ModelPaperwork.backToSummary
    ModelPaperwork.setBackToSummary(false)

    if (goBack) {
      //>>>>>
      navigate(-1)
    } else if (ModelPaperwork.paperwork.workInjury) {
      //Skip insurance
      //>>>>>
      navigate(Path.Paperwork5)
    } else {
      //>>>>>
      navigate(Path.Paperwork4)
    }
  }, [navigate])

  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={
          ModelPaperwork.backToSummary
            ? Path.PaperworkSummary
            : ModelPaperwork.patientIsOver18()
            ? Path.Paperwork1
            : Path.Paperwork2
        }
        title='Work Related'
        progress={3 / 6}
      />
      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          <h2>Are you here at the request of your employer?</h2>
          <div className={css.fieldWrapExtra}>
            <Selector
              buttonStyle
              items={['Yes', 'No']}
              selected={ModelPaperwork.paperwork.workInjury ? 'Yes' : 'No'}
              clicked={(value) => {
                ModelPaperwork.paperwork.workInjury = value === 'Yes'
                ModelPaperwork.setPaperwork(ModelPaperwork.paperwork)
              }}
            />
          </div>

          {ModelPaperwork.paperwork.workInjury && (
            <div>
              <PaperworkEmployerInfo
                values={ModelPaperwork.paperwork}
                change={(values) => ModelPaperwork.setPaperwork(values)}
              />
              <PaperworkEmployerReason
                values={ModelPaperwork.paperwork}
                change={(values) => ModelPaperwork.setPaperwork(values)}
              />
            </div>
          )}
        </div>
        {/* === Continue === */}
        <div className={css.buttonWrap}>
          <Button
            primary
            clicked={clickContinue}
            text={ModelPaperwork.backToSummary ? 'Save Changes' : 'Continue'}
          />
        </div>
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkEmployer)
